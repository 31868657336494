import AddressInput from '@/app/[locale]/(index)/components/AddressInput';
import useAddressSearch from '@/app/[locale]/shop/components/commons/AddressModal/hook';
import LinkToLogin from '@/components/design-system/LinkToLogin';
import {IMGPW} from '@/config';
import {useLocalization} from '@/hooks/useLocalization';
import {ILocation} from '@/models/location';
import {useRouter} from '@/navigation';
import useCart from '@/stores/cart/store';
import {useTranslations} from 'next-intl';
import Image from 'next/image';
import {useEffect, useState} from 'react';
import useSession from '../../../../stores/session/useSession';
import DottedItems from './DottedItems';
import MainSectionBanner from '@/app/[locale]/(index)/components/MainSectionBanner';

interface Props {
  highlightedCity?: string;
}

interface AddressBarProps {
  onPlaceSelect: (location: ILocation) => void;
  highlightedCity?: string;
  initialPlace?: ILocation;
}

const mapLocationToPlace = (location: ILocation) => ({
  formattedAddress: location.formattedAddress!,
  city: location.city,
  address: location.address,
  postalCode: location.postalCode!,
  streetNumber: location.streetNumber,
  // @ts-ignore
  coordinates: location.coordinates!,
});

export function AddressBar(props: AddressBarProps) {
  const {highlightedCity, ...childProps} = props;
  const {gift} = useCart((state) => state);
  const t = useTranslations('common');
  const {user} = useSession();
  const {setIsGift} = useCart((state) => state);
  const {location} = useLocalization();

  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState(false);

  // Set recent location as initial place.
  if (!!location && !childProps.initialPlace) {
    childProps.initialPlace = mapLocationToPlace(location);
  }

  useEffect(() => {
    setIsActive(gift);
  }, [gift]);

  return (
    <div
      className={'bg-white py-4 px-6 shadow-xl rounded-lg flex flex-col gap-3'}
    >
      <div className="text-left text-base font-medium text-greyscale-label tracking-tight">
        {!highlightedCity ? (
          t('AddressBar.title')
        ) : (
          <span>
            {t('AddressBar.title-highlighted-city')}
            <span className="text-base bg-primary rounded py-1 px-2 text-white">
              {highlightedCity}
            </span>
          </span>
        )}
        &nbsp;
        {!user ? (
          <>
            {t('AddressBar.or')}
            <LinkToLogin>{t('AddressBar.login')}</LinkToLogin>{' '}
            {t('AddressBar.choose-saved-address')}
          </>
        ) : (
          <>{t('AddressBar.or-select-address')}</>
        )}
      </div>

      <ul className="flex flex-wrap pl-0 mb-0 list-none" role="tablist">
        <li className="">
          <a
            className={
              'py-2 px-4 font-medium no-underline select-none border-b-2 ' +
              'text-greyscale-gray-dark duration-300 hover:text-primary border-b-transparent ' +
              (!isActive ? '!border-b-primary !text-primary' : '')
            }
            data-toggle="tab"
            role="tab"
            onClick={() => setIsGift(false)}
          >
            {t(`AddressBar.tab-delivery`)}
          </a>
        </li>
        <li className="">
          <a
            className={
              'py-2 px-4 font-medium no-underline select-none border-b-transparent border-b-2 ' +
              'text-greyscale-gray-dark duration-300 hover:text-primary ' +
              (isActive ? '!border-b-primary !text-primary' : '')
            }
            data-toggle="tab"
            role="tab"
            onClick={() => setIsGift(true)}
          >
            {t(`AddressBar.tab-gift`)}
          </a>
        </li>
      </ul>
      <AddressInput
        onChangeQ={() => setError(true)}
        bodyLoggedMessage={false}
        suggestionsClassName="bg-white rounded absolute"
        onChangeAddress={props.onPlaceSelect}
      />
      {error && (
        <div className="text-base text-red-600">
          {t('AddressBar.select-address-from-list')}
        </div>
      )}
    </div>
  );
}

export default function MainSection(props: Props) {
  const router = useRouter();
  const {updateGlobalLocation} = useAddressSearch('');
  const t = useTranslations('index');

  const onPlaceSelect = async (location: ILocation) => {
    await updateGlobalLocation(location);
    router.push('/shop');
  };

  return (
    <section className="bg-greyscale-pink">
      <div
        className={
          'xl:w-[1170px] xl:max-w-[1170px] mx-auto relative ' +
          'sm:bg-home-image bg-no-repeat bg-right-top bg-[size:66%]'
        }
      >
        <div className="grid lg:grid-cols-2 justify-center items-center">
          <div className="px-4 xl:px-0 flex flex-col gap-4">
            <Image
              src={`${IMGPW}/claim_home.gif`}
              alt="Vini, birre e drink a casa tua, subito!"
              width={400}
              height={0}
            />
            <AddressBar
              highlightedCity={props.highlightedCity}
              onPlaceSelect={onPlaceSelect}
            />
            <DottedItems />
          </div>
        </div>
        <MainSectionBanner
          text={t('CorporateGifting.section-title')}
          linkUrl="/business/eventi-aziendali/regalistica-aziendale"
          linkText={t('CorporateGifting.main-cta')}
          imagePath={`${IMGPW}/business/per_aziende.png`}
        />
      </div>
      <div className="bg-wave bg-repeat-x aspect-[1440/190]" />
    </section>
  );
}
