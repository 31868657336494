import {useEffect, useState} from 'react';
import {Link} from '@/navigation';
import Image from 'next/image';

interface Props {
  text: string;
  linkUrl: '/business/eventi-aziendali/regalistica-aziendale'; // Aggiungere i link in caso di modifica al banner
  linkText: string;
  imagePath: string;
  orientation?: 'horizontal' | 'vertical';
}

export default function MainSectionBanner(props: Props) {
  const [windowDimension, detectHW] = useState({
    winWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
    winHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
  });

  const detectSize = () => {
    detectHW({
      winWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
      winHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimension]);

  return props.orientation === 'horizontal' ? (
    <div
      className="hidden absolute shadow-xl rounded-lg md:flex py-5"
      style={{
        paddingLeft: 24,
        paddingRight: 16,
        top: 25,
        right: windowDimension.winWidth > 1300 ? -50 : 16,
        maxHeight: 160,
        maxWidth: 350,
        backgroundColor: 'white',
      }}
    >
      <div style={{flex: 0.65}} className="flex flex-col justify-center">
        <h6>{props.text}</h6>
        <Link href={props.linkUrl} className="text-lg">
          {props.linkText}
        </Link>
      </div>
      <div style={{flex: 0.35}} className="flex items-center justify-center">
        <Image
          width={150}
          height={150}
          src={props.imagePath}
          alt="Corporate gifting"
        />
      </div>
    </div>
  ) : (
    <div
      className="absolute shadow-xl rounded-lg hidden md:flex flex-col px-3"
      style={{
        top: 25,
        right: 20,
        width: 200,
        paddingTop: 16,
        paddingBottom: 24,
        backgroundColor: 'white',
        opacity: 0.9,
      }}
    >
      <div
        style={{flex: 0.35}}
        className="flex items-center justify-center mb-1"
      >
        <Image
          width={150}
          height={150}
          src={props.imagePath}
          alt="Corporate gifting"
        />
      </div>
      <h6>{props.text}</h6>
      <Link className="text-lg" href={props.linkUrl}>
        {props.linkText}
      </Link>
    </div>
  );
}
